import React from "react"

import styles from "../../styles/main.module.css"

export default function HelloWorld() {
    const text = "lol. Glad to have this up and running. It took me a little longer than I hoped even though it was so simple. I tried to add more features but really fought with the CSS and ended up settling for the simpler UI you see now. I plan to make an interesting post within the next week or so.";
    return (
      <React.Fragment>
        <h2>Hello World!</h2>
        <hr className={styles.top_seperator} />
        <p>{text}</p>
        <div className={styles.phone_home}>
          <a href="/">Click me to return home.</a>
        </div>
      </React.Fragment>
    )
}
